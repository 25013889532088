import React from "react";
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from "gatsby";
import { WMKLink } from "wmk-lib";

const NavWrap = styled.div`
  background: #8AD2CE;
  position: relative;
  .container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    font-weight: 600;
    .logo-link-wrap {
      width: 10%;
      position: relative;
      z-index: 1;
      img {
        height: 17.8rem;
      }
    }
    .nav-items-wrap {
      width: 90%;
      position: relative;
      z-index: 0;
      left: -2rem;
      display: flex;
      flex-direction: column;
      .nav-item {
        position: relative;
        margin-top: 1rem;          
        a {
          display: block;
          position: relative;
          left: 0;
          z-index: 1;
          width: 50vw;
          max-width: 45rem;
          height: 100%;
          padding: 5px 1rem 5px 8rem;
          color: white;
          background: linear-gradient(to bottom, #FE822C 0%, #FE822C 49%, #F47B2A 51%, #F47B2A 100%);
          clip-path: polygon(0% 0%, 95% 0%, 97% 50%, 95% 100%, 0% 100%);
          border: 2px solid white;  
          transition: all .3s ease;
        }  
        .right-border {
          position: absolute;
          z-index: 0;
          top: 0;
          left: 3px;
          clip-path: polygon(0% 0%, 95% 0%, 97% 50%, 95% 100%, 0% 100%);
          background: white;
          height: 100%;
          width: 50vw;
          max-width: 45rem;
          transition: all .3s ease;
        }
        :hover {
          .right-border {
            left: 18px;
            transition: all .3s ease;
          }
          a {
            left: 15px;
            transition: all .3s ease;
            text-decoration: none;
          }
        }
      }  
    }
  }
  .ntmwd-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1rem;
    img {
      width: 150px;
    }
    p {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 991px) {
    .container {
      padding: 0 5px !important; 
      margin: 0 !important;
    }
    .nav-items-wrap {
      font-size: 2vw;
      .nav-item {
        a {
          width: 70vw !important;
          padding: 5px 0rem 5px 11.5rem !important;
          clip-path: polygon(0% 0%, 95% 0%, 97% 50%, 95% 100%, 0% 100%) !important;
        }
        .right-border {
          width: 70vw !important;
          clip-path: polygon(0% 0%, 95% 0%, 97% 50%, 95% 100%, 0% 100%) !important;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .nav-items-wrap {
      font-size: 2.75vw;
      .nav-item {
        a {
          width: 75vw !important;
          padding: 5px .5rem 5px 11.5rem !important;
          clip-path: polygon(0% 0%, 95% 0%, 97% 50%, 95% 100%, 0% 100%) !important;
        }
        .right-border {
          width: 75vw !important;
          clip-path: polygon(0% 0%, 95% 0%, 97% 50%, 95% 100%, 0% 100%) !important;
        }
      }
    }
    .ntmwd-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: .25rem;
      img {
        width: 18vw;
      }
      p {
        font-size: .7rem;
        margin-bottom: 0;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .nav-items-wrap {
      font-size: 2.5vw;
    }
  }
`

const Nav = () => {
  const { nav } = useStaticQuery(query);
  return (
    <NavWrap>
      <Container>
        <div className="logo-link-wrap">
          <a href="https://water4otter.org">
            <img src="/W4O_logo.png" alt="water 4 otter logo" />
          </a>        
        </div>
        <div className="nav-items-wrap">
          {nav.edges.map((link, i) => {
            const { to, title, target } = link.node;
            return (
              <div className="nav-item" key={title + i}>
                <WMKLink to={to} target={target}>
                  {title}                
                </WMKLink>
                <div className="right-border" />
              </div>
            )
          })}
        </div>
      </Container>     
      <div className="ntmwd-wrap">
        <p>Brought to you by</p>
        <a href="https://ntmwd.com/help-us-conserve/" target="_blank" rel="noopener noreferrer">
          <img src="/NTMWD-logo.png" alt="ntmwd logo" />
        </a>        
      </div> 
    </NavWrap>
  )
};

export default Nav;

const query = graphql`
  {
    nav: allNavJson {
      edges {
        node {
          ...NodeNavFields
        }
      }
    }
  }
`;

import React from 'react'
import styled from 'styled-components'
 
const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  padding: 2rem 0 !important;
  a {
    img {
      height: 7rem;
      transition: all .3s ease;
    }
    :hover {
      img {
        height: 8rem;
        transition: all .3s ease;
      }
    }
  }
`
 
const Footer = props => {
  return (
    <Wrap>
      <a href="https://ntmwd.com" target="_blank" rel="noreferrer noopener">
        <img src="/visit_ntmwd.png" alt="ntmwd logo" />
      </a>      
    </Wrap>
  )
}
 
export default Footer
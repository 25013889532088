/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { MainLayout } from "wmk-lib";

import Header from "./Header/Header";
import Footer from "./Footer";

const Wrap = styled.div`
  font-family: Muli, serif;
  @media only screen { *:focus, *:active { outline: none } }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Wrap>
        <MainLayout
          Header={() => <Header siteTitle={data.site.siteMetadata.title} />}
          Footer={() => (
            <Footer />
          )}
          style={{fontFamily: 'Museo, serif'}}   //-- not working, not sure why
        >
          {children}
        </MainLayout>
      </Wrap>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
